import React from 'react';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { Fade } from 'react-awesome-reveal';
import { graphql } from 'gatsby';
import Layout from 'layouts/Clean';
import {
  Title,
  Text,
  Scrollable,
  HorizontalScrollingLoop,
  Hero,
  SEO,
  TShapedBlock,
  SplitTextBlock,
  Button,
  SecretsBlock,
  ImageBlock,
} from 'components';
import heroImg from '../images/eventi/01-eventi.jpg';
import img02 from '../images/eventi/02-eventi.jpg';
import img04 from '../images/eventi/architettura.jpg';
import img05 from '../images/eventi/05-eventi.jpg';
import img06 from '../images/eventi/06-eventi.jpg';
import img09 from '../images/eventi/09-eventi.jpg';
import img10 from '../images/eventi/banchetto-lucchesi.jpg';
import img11 from '../images/eventi/evento-lucchesi-verticale.jpg';

const EventiPage = ({ location }) => {
  const { t } = useTranslation();

  const textileImages = [img02, img11, img04];

  const PAGE_PROPERTIES = {
    title: (
      <Trans>
        Una location <font className="text-secondary">esclusiva</font> per
        <font className="text-secondary"> stupire</font> ospiti e clienti
      </Trans>
    ),
    image: img09,
    description: (
      <Trans>
        Spazi interni ed esterni a ridosso delle mura storiche della città, all’interno di un luogo
        che coniuga archeologia industriale, arredamento moderno, immerso nei tessuti e in dettagli
        unici.
      </Trans>
    ),
  };

  // const teamImages = [img04, img05, img06];

  return (
    <Layout location={location}>
      <SEO
        keywords={['lucchesi, eventi, tessuti, location, shooting']}
        title={t("Location industriale per eventi esclusivi")}
        description={t("Uno spazio unico tra modernità e archeologia industriale per il tuo evento privato o per il tuo shooting")}
        location={location}
      />
      <section className="mt-12 md:mt-40">
        <Hero invert {...PAGE_PROPERTIES}></Hero>
      </section>
      <section className="my-12">
        <HorizontalScrollingLoop
          firstLine={
            <Trans>
              Un <font className="text-secondary">macrocosmo</font> fatto di cultura, passione,
              innovazione, <font className="text-secondary">archeologia industriale</font>, impegno,
              tecnica e raffinatezza.
            </Trans>
          }
          secondLine={
            <Trans>
              Un <font className="text-secondary">macrocosmo</font> fatto di cultura, passione,
              innovazione, <font className="text-secondary">archeologia industriale</font>, impegno,
              tecnica e raffinatezza.
            </Trans>
          }
        />
      </section>
      <section className="mt-24 mb-96">
        <Scrollable images={textileImages}></Scrollable>
      </section>
      <section className="mt-48 mb-24 px-12 md:px-60 min-h-[50vh] flex flex-col justify-center items-center relative">
        <div className="z-20">
          <div className="my-4">
            <Trans>
              <Text>ESCLUSIVI</Text>
            </Trans>
          </div>
          <Trans>
            <Title>
              INAUGURAZIONI <font className="text-secondary">SFILATE</font> CONCERTI
              <font className="text-secondary"> FESTE</font> MOSTRE VERNISSAGE
            </Title>
          </Trans>
        </div>

        <div className="absolute w-40  md:w-80 h-auto bottom-12 right-0 z-10">
          <img className="w-full h-full object-cover" src={img10}></img>
        </div>
        <div className="absolute w-60 h-auto -bottom-12 left-0 md:left-0 z-10">
          <img className="w-full h-full object-cover" src={img06}></img>
        </div>
      </section>
      <section className="my-24">
        <TShapedBlock
          title={t('Contattaci per organizzare uno shooting fotografico con un set unico')}
          image={img05}
          ctaHref="/contatti"
          cta={t('Vai ai contatti')}></TShapedBlock>
      </section>

      <section className="h-screen w-screen relative flex flex-row items-center justify-center">
        <ImageBlock label={''} text={''} image={heroImg}></ImageBlock>
      </section>
    </Layout>
  );
};

export default EventiPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
